<section class="grid-container padding-x-sm-2 padding-y-2 usa-section ">
  <div class="modal-content">
    <!-- <h2>{{ appId ? 'Edit App' : 'Create App' }}</h2> -->
    <h2>Details</h2>
    <form (ngSubmit)="onSubmit()" #myAppForm="ngForm">
      <div class="form-group">
        <label for="appName">App Name*</label>
        <input type="text" id="appName" class="usa-input" [(ngModel)]="appForm.name" 
        name="appName" required #appNameRef="ngModel" />
        <div *ngIf="appNameRef.touched && appNameRef.invalid" class="error-message">
        App Name is required
        </div>
      </div>

      <div class="form-group">
        <label for="description">Description*</label>
        <textarea id="description" class="usa-input" [(ngModel)]="appForm.description" 
        name="description" #appDescriptionRef="ngModel" required>
        </textarea>
        <div *ngIf="appDescriptionRef.touched && appDescriptionRef.invalid" class="error-message">
          Description is required
        </div>
      </div>

      <div class="form-group" *ngIf="appId">
        <input class="usa-checkbox__input usa-checkbox__input--tile margin-left-1" id="active" type="checkbox"
          name="active" [(ngModel)]="appForm.active" />
        <label class="usa-checkbox__label" for="active">Active</label>
      </div>

      <div class="form-group" *ngIf="appId">
        <label for="unsuccessfulAttempts">Last Reconciliation Time</label>
        <div class="usa-input-group d-flex no-gap align-items-center">
          <input type="text" id="unsuccessfulAttempts" class="usa-input" [(ngModel)]="formattedDate"
            name="lastReconciled" readonly />
          <button [disabled]="appUserCount.reconcileFlag" type="button" class="usa-button no-gap-button" 
            (click)="reconsileUser()">
            Reconcile
          </button>
          <span *ngIf="appUserCount.reconcileFlag"> {{appUserCount.processedUser}}/{{appUserCount.totalNumberOfUsers}}
          </span>
        </div>
      </div>
      <div class="margin-top-4 delete-button-group">
        <button [disabled]="myAppForm.invalid" type="submit" class="usa-button">
          {{ appId ? 'Update' : 'Create' }}
        </button>
        <button *ngIf="appId" type="button" class="usa-button delete-button" (click)="openDeleteConfirmation()">
          Delete
        </button>
        <button type="button" class="usa-button delete-button-outline" (click)="closeForm()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</section>
<div class="delete-modal" *ngIf="showDeleteConfirmation">
  <div class="delete-modal-content">
    <h3>Confirm Deletion</h3>
    <p>Deleting the App will delete App Details, App Configuration, and possibly all Users associated with the App. Are
      you sure?</p>
    <div class="delete-modal-actions">
      <button class="usa-button" (click)="confirmDelete()">Confirm</button>
      <button class="usa-button usa-button--outline" (click)="cancelDelete()">Cancel</button>
    </div>
  </div>
</div>