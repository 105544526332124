import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { NavigateService } from '@src/app/core/services/navigate.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './app-side-nav.component.html',
  styleUrls: ['./app-side-nav.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class AppSideNavComponent implements OnInit {
  public currentPath!: string;
  public paramsId!: string;
  constructor(private activeRoute: ActivatedRoute, private router: Router, private navigateService: NavigateService) {

  }

  ngOnInit(): void {
    this.paramsId = this.activeRoute.firstChild?.snapshot.params['appId'];
    this.currentPath = this.router.url;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentPath = this.router.url;
      }
    })
  }

  get getEditPath() {
    return this.navigateService.getAppEditPath(this.paramsId);
  }

  get getConfigPath() {
    return this.navigateService.getAppConfigPath(this.paramsId);
  }

  get getUsersPath() {
    return this.navigateService.getAppUsersPath(this.paramsId);
  }
  get getAppActivityPath() {
    return this.navigateService.getAppActivityPath(this.paramsId);
  }
  get isActiveEditPath() {
    return this.currentPath === this.getEditPath;
  }
  get isActiveConfigPath() {
    return this.currentPath === this.getConfigPath;
  }
  get isActiveUsersPath() {
    return this.currentPath === this.getUsersPath;
  }
  get isAppActivity() {
    return this.currentPath === this.getAppActivityPath;
  }
}
