import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@src/app/core/services/auth.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  standalone: true,
  imports: [FormsModule,CommonModule,ReactiveFormsModule]
})
export class ResetPasswordComponent implements OnInit {

  newPassword!: string;
  confirmPassword!: string;
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;
  isLoading: boolean = false;
  token!: string;
  username!: string;

  constructor(public router: Router, 
    private authService: AuthService,
    private route: ActivatedRoute
    ) {
    
   }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('exchangeToken') || '';
    this.username = this.route.snapshot.queryParamMap.get('username') || '';
    console.log(this.token);
  }

  onSubmit() {
    this.isLoading = true;
    this.authService.resetPassword({ extoken: this.token, newpassword: this.confirmPassword, username: this.username})
    .pipe(
      finalize(() => {
       this.isLoading = false;
      })
    )
    .subscribe({
      next:() =>{
        this.router.navigate(['/login']);
      }
    })
  }

  get isSubmitDisabled(): boolean {
    return this.newPassword !== this.confirmPassword || !this.newPassword || !this.confirmPassword;
  }
  get isShowConfirm(): boolean {
    return this.newPassword && this.confirmPassword && this.newPassword != this.confirmPassword || false;
  }


}
