<section class="grid-container padding-x-sm-2 padding-y-2">
  <h1 class="usa-heading center">Dashboard</h1>
   
    <div class="grid-row grid-gap">
      
      <div class="grid-col-4">
        <div class="usa-card">
          <div class="usa-card__container">
            <div class="usa-card__body">
              <p><strong>Total Apps:</strong> {{totalApps}}</p>
            </div>
          </div>
        </div>
      </div>
        <div class="grid-col-4">
          <div class="usa-card">
            <div class="usa-card__container">
              <div class="usa-card__body">
                <p><strong>Total Users:</strong> {{totalUsers}}</p>
              </div>
            </div>
          </div>
      </div>

      <div class="grid-col-4">
        <div class="usa-card">
          <div class="usa-card__container">
            <div class="usa-card__body">
              <p><strong>Live Users:</strong> {{activeUsers}}</p>
            </div>
          </div>
        </div>
      </div>
     

       <!-- Charts Section -->
      <div class="grid-col-6">
        <div class="usa-card">
          <div class="usa-card__container">
            <div class="usa-card__header header-item">
              <p class="margin-0"><strong>User Activity</strong></p>
              <select class="usa-select small-select" [(ngModel)]="selectedDays" (ngModelChange)="geUserActivity()">
                <option *ngFor="let range of userActivity" [value]="range.value">
                  {{ range.label }}
                </option>
              </select>
            </div>
              <div class="usa-card__body">
                <canvas baseChart 
                  [datasets]="usersChartData"
                  [labels]="usersChartLabels"
                  [options]="chartOptions"
                  [legend]="true"
                  [type]="'line'">
                </canvas>
              </div>
            </div>
        </div>
      </div>
  
      <div class="grid-col-6">
        <div class="usa-card">
          <div class="usa-card__container">
            <div class="usa-card__header header-item">
              <p class="margin-0"><strong>Apps Users</strong></p>
              <select class="usa-select small-select" [(ngModel)]="selectedAppNumber" (ngModelChange)="filterTopApps()">
                <option *ngFor="let range of appsActivity" [value]="range.value">
                  {{ range.label }}
                </option>
              </select>
            </div>
            <div class="usa-card__body">
              <canvas baseChart 
              [datasets]="appChartData"
              [labels]="appChartLabels"
              [options]="chartOptions"
              [legend]="true"
              chartType="bar">
              </canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-col-6">
        <div class="usa-card">
          <div class="usa-card__container">
            <div class="usa-card__header header-item">
              <p class="margin-0"><strong>Apps Session Activity</strong></p>
              <select class="usa-select small-select" [(ngModel)]="selectedSessionNumber" (ngModelChange)="getAppSessionActivity()">
                <option *ngFor="let range of appSessionActivity" [value]="range.value">
                  {{ range.label }}
                </option>
              </select>
            </div>
            <div class="usa-card__body">
              <canvas baseChart 
              [datasets]="sessionChartData"
              [labels]="sessionChartLabels"
              [options]="chartOptions"
              [legend]="true"
              chartType="bar">
              </canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-col-6">
        <div class="usa-card">
          <div class="usa-card__container">
            <div class="usa-card__header header-item">
              <p class="margin-0"><strong>Anonymous Activity</strong></p>
              <select class="usa-select small-select" [(ngModel)]="anonymousSelectedDays" (ngModelChange)="getAnonymousUserActivity()">
                <option *ngFor="let range of userActivity" [value]="range.value">
                  {{ range.label }}
                </option>
              </select>
            </div>
              <div class="usa-card__body">
                <canvas baseChart 
                  [datasets]="anonymousChartData"
                  [labels]="anonymousChartLabels"
                  [options]="chartOptions"
                  [legend]="true"
                  [type]="'line'">
                </canvas>
              </div>
            </div>
        </div>
      </div>
  
    </div>
  
  
</section>

  