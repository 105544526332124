<section class="grid-container padding-x-sm-2 padding-y-2">
  <h1 class="usa-heading">App Activities</h1>
  <div class="usa-identifier width-full align-start">

    <div class="search-bar">
      <!-- Limit Dropdown -->
      <label>Limit:</label>
      <select [(ngModel)]="filters.limit">
        <option value="">Select</option>
        <option *ngFor="let l of limits" [value]="l">{{ l }}</option>
      </select>

      <!-- Status Dropdown -->
      <label>Status:</label>
      <select [(ngModel)]="filters.status">
        <option value="">All</option>
        <option value="Valid">Valid</option>
        <option value="Invalid">Invalid</option>
      </select>

      <!-- Date Pickers -->
      <label>Start Date:</label>
      <input type="date" [(ngModel)]="filters.startDate" />

      <label>End Date:</label>
      <input type="date" [(ngModel)]="filters.endDate" />

      <button (click)="onSearch()" class="usa-button">Search</button>

      <ul>
        <li *ngFor="let result of searchResults">{{ result }}</li>
      </ul>
    </div>

  </div>
  <!-- Grid for User Table -->
  <div class="table-grid" *ngIf="appActivity().length > 0">
    <table class="usa-table usa-table--borderless usa-table--striped full-width-table">
      <thead>
        <tr>
          <th>ACTION</th>
          <th>User Name</th>
          <th>MESSAGE</th>
          <th>TIMESTAMP</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let app of paginateAppActivity()">
          <td>{{ app.action }}</td>
          <td>{{ app.userName }}</td>
          <td>{{ app.message }}</td>
          <td class="max-width-column">{{ app.createTimeStamp | date:'yyyy-MM-dd HH:mm:ss' }}</td>
        </tr>
      </tbody>
    </table>
    <app-paginator [pageNumber]="currentPage()" [totalData]="filteredAppActivity().length || 0" [pageSize]="pageSize"
      (pageChanged)="onPageChanged($event)"></app-paginator>
  </div>
  <div class="table-grid" *ngIf="appActivity().length == 0">No Activities found</div>
</section>