import { CommonModule, NgFor } from '@angular/common';
import { Component, DestroyRef, OnInit, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { PaginatorComponent } from '@src/app/components/paginator/paginator.component';
import { IApp, IApps } from '@src/app/core/models/app.model';
import { AppService } from '@src/app/core/services/app.service';
import { NavigateService } from '@src/app/core/services/navigate.service';
import { searchDataByKeys } from '@src/app/core/utils/searchDataByKeys';

@Component({
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.css'],
  standalone: true,
  imports: [FormsModule, NgFor, CommonModule, PaginatorComponent],
})
export class AppListComponent implements OnInit {
  appData = signal<IApps>([]);
  filterCriteria = signal('');
  currentPage = signal<number>(1);
  pageSize = 10;

  filteredApps = computed(() => {
    const search = this.filterCriteria().toLowerCase();
    return searchDataByKeys(this.appData(), search, ['name', 'description']);
  });

  paginateApps = computed(() => {
    const startIndex = (this.currentPage() - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.filteredApps().slice(startIndex, endIndex);
  });

  constructor(private appService: AppService,
     private navigateService: NavigateService,
     private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.getApps();
  }

  getApps(): void {
    this.appService.getApps().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (apps) => {
        this.appData.set(apps);
      },
    });
  }

  onFilter(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.currentPage.update(() => 1);
    this.filterCriteria.set(input);
  }

  editApp(app: IApp): void {
    this.navigateService.toAppEditForm(app.id);
  }

  deleteApp(appId: number): void {
    this.appService.deleteApp(appId).subscribe({
      next: () => {
        this.appData.update((apps) => apps.filter(app => app.id !== appId));

      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  createApp(): void {
    this.navigateService.toAppCreateForm();
  }

  onPageChanged(page: number): void {
    this.currentPage.update(() => page);
  }
}
