import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserService } from '../core/services/user.service';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule]
})
export class ResetPasswordModalComponent  {
  @Input() userId!: string;
  @Output() closeModal = new EventEmitter<void>();

  newPassword: string = '';
  confirmPassword: string = '';
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;
  isModalOpen = false;
  isLoading: boolean = false;

  constructor(private userService: UserService){}

  get isSubmitDisabled(): boolean {
    return this.newPassword !== this.confirmPassword || !this.newPassword || !this.confirmPassword;
  }
  get isShowConfirm(): boolean {
    return this.newPassword && this.confirmPassword && this.newPassword != this.confirmPassword || false;
  }

  onCloseModal() {
   this.isModalOpen = false;
   this.clearFields();
  }

  onResetPassword(){
    this.isLoading = true;
    this.userService.resetAdminPassword(this.userId, this.newPassword).subscribe({ next:(response) => {
      this.isLoading = false;
      this.clearFields();
      this.onCloseModal()
    }, error:(err)=> {
      this.isLoading = false;
      console.log(err);
    },})
  }
  private clearFields() {
    this.newPassword = '';
    this.confirmPassword = '';
    this.isPasswordVisible = false;
    this.isConfirmPasswordVisible = false;
  }
}
