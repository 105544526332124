<section class="grid-container padding-x-sm-2 padding-y-2">
  <h1 class="usa-heading">Apps</h1>

  <!-- Filter and Create -->
  <div class="filter-grid">
    <input
      type="text"
      class="usa-input"
      placeholder="Search App..."
      (input)="onFilter($event)"
    />
    <button class="usa-button" (click)="createApp()">Create</button>
  </div>

  <!-- App Table -->
  <div class="table-grid">
    <table class="usa-table usa-table--borderless usa-table--striped full-width-table">
      <thead>
        <tr >
          <th>Name</th>
          <th>Description</th>
          <th>Active</th>
          <!-- <th>Actions</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let app of paginateApps()">
          <td><a  href="javascript:void(0)" (click)="editApp(app)">{{ app.name }}</a></td>
          <td>{{ app.description }}</td>
          <td>{{ app.active ? 'Yes' : 'No' }}</td>
          <!-- <td>
            <button
              class="usa-button usa-button--small usa-button--secondary"
              (click)="editApp(app)"
            >
              Edit
            </button>
            <button 
              class="usa-button usa-button--small usa-button--danger" 
              (click)="deleteApp(app.id)">
              Delete
            </button>
          </td> -->
        </tr>
      </tbody>
    </table>
    <app-paginator
      [pageNumber]="currentPage()"
      [totalData]="filteredApps().length || 0"
      [pageSize]="pageSize"
      (pageChanged)="onPageChanged($event)"
    ></app-paginator>
  </div>
</section>
