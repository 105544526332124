import { Injectable } from '@angular/core';
import { LoggedUser } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  constructor() { }

  isLogin(): boolean{
    const isTokenFound = !!this.getToken();
    const isSessionFound = !!this.getSession();
    return isTokenFound && isSessionFound
  }

  logOut(){
    this.clearAll()
  }

  setToken (token: string): void  {
    this.setItemLocalStore('token', token)
  }

  getToken (): string  {
   return this.getItemLocalStore('token')
  }
  setUserInfo (user: LoggedUser): void  {
    const userInfo =  this.jsonEncript(user || {})
    this.setItemLocalStore('user', userInfo)
  }

  getUserInfo (): LoggedUser  {
   const encInfo =  this.getItemLocalStore('user');
   return encInfo ? this.jsonDecript(encInfo) : {} as LoggedUser
  }
  removeToken (): void  {
    this.getItemLocalStore('token')
  }

  setSession (token: string): void  {
    this.setItemLocalStore('session', token)
  }

  getSession (): string  {
   return this.getItemLocalStore('session')
  }
  removeSession (): void  {
    this.getItemLocalStore('session')
  }
  clearAll (): void  {
    sessionStorage.clear()
  }

  setItemLocalStore (name: string, payload: any): void {
    sessionStorage.setItem(name, payload);
  }
  
  getItemLocalStore(name: string): string {
      return sessionStorage.getItem(name) ?? '';
  }
  deleteItemLocalStore(name: string): void {
    return sessionStorage.removeItem(name);
}

  strEncript (data: string): string {
    return btoa(data);
  }

  strDecript(data: string):string {
      return atob(data);
  }

  public jsonEncript(data: object | string): string {
    return btoa(JSON.stringify(data));
  }

  jsonDecript<T>(data: string): T {
    if (!data) {
      return null as T;
    }
    return JSON?.parse(atob(data));
  }
}
