import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpErrorsInterceptor
  implements HttpInterceptor
{
 
  constructor(
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
   
    return next.handle(request).pipe(
      // retry(5),
      // 1.5s 90000 timeout
      timeout(240000),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          
        }

        if (error.status === 401) {
          return this.authService.singOut();
        }
        
        if (error.error instanceof ErrorEvent) {
          ///  client-side error
        } else if (error.status === 500) {
          error = { ...error, error: {}, status: 500 };
          error.error.message = `Internal server error`;
        } else if (error instanceof TimeoutError) {
          error = { ...error, error: {}, status: 408 };
          error.error.message = `Request timeout`;
        } else if (error.error instanceof ProgressEvent) {
          error = { ...error, error: error.error, status: 503 };
          error.error.message = `Server unavailable`;
        }
        return throwError(() => error);
      })
    );
  }

 

}