import { Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AppFormComponent } from './app-management/app-form/app-form.component';
import { AppListComponent } from './app-management/app-list/app-list.component';
import { AppSideNavComponent } from './app-management/app-side-nav/app-side-nav.component';
import { ConfigFormsComponent } from './app-management/config-forms/config-forms.component';
import { AppWrapperComponent } from './app-wrapper/app-wrapper.component';
import { ForgotPasswordComponent } from './auth-management/forgot-password/forgot-password.component';
import { LoginComponent } from './auth-management/login/login.component';
import { ResetPasswordComponent } from './auth-management/reset-password/reset-password.component';
import { authGuard } from './core/guard/auth.guard';
import { OtpComponent } from './auth-management/otp-auth/otp.component';
import { UserFormComponent } from './user-management/user-form/user-form.component';
import { UserListComponent } from './user-management/user-list/user-list.component';
import { UserSessionsComponent } from './user-management/user-sessions/user-sessions.component';
import { UserSideNavComponent } from './user-management/user-side-nav/user-side-nav.component';
import { UserActivityComponent } from './user-management/user-activity/user-activity.component';
import { AppActivityComponent } from './app-management/app-activity/app-activity.component';


export const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'otp', component: OtpComponent },
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'app', component: AppWrapperComponent,
        canActivateChild: [authGuard],
        children: [
            { path: '', redirectTo: '/admin/users', pathMatch: 'full' },
            { path: 'dashboard', component: AdminDashboardComponent },
            { path: 'admin/users', component: UserListComponent },
            { path: 'admin/apps', component: AppListComponent },
            { path: 'admin/users/create', component: UserFormComponent },
            {
                path: 'admin/users', component: UserSideNavComponent,
                children: [
                    { path: 'edit/:id', component: UserFormComponent },
                    { path: 'sessions/:id', component: UserSessionsComponent },
                    { path: 'activity/:id', component: UserActivityComponent }
                ],
            },
            {
                path: 'admin/apps', component: AppSideNavComponent,
                children: [
                    { path: 'edit/:appId', component: AppFormComponent },
                    { path: 'configuration/:appId', component: ConfigFormsComponent },
                    { path: 'users/:appId', component: UserListComponent },
                    { path: 'activity/:appId', component: AppActivityComponent }
                ],
            },
            { path: 'admin/apps/create', component: AppFormComponent },
            { path: 'configuration', component: ConfigFormsComponent }
        ]
    },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'forgot/reset', component: ResetPasswordComponent },
    { path: '**', redirectTo: '/login' },
];