import { CommonModule, NgFor } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ISessionStates } from '@src/app/core/models/user.model';
import { UserService } from '@src/app/core/services/user.service';

@Component({
  selector: 'app-user-session-tracking-modal',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './user-session-tracking-modal.component.html',
  styleUrl: './user-session-tracking-modal.component.css'
})
export class UserSessionTrackingModalComponent {

  // @Input() userId!: number;
  @Input() sessionStates!: ISessionStates;
  @Output() closeModal = new EventEmitter<void>();

  onCloseModal() {
    this.closeModal.emit();
  }

}
