import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

const loaderSkipUrlList: string[] = ['/process/users'];
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!loaderSkipUrlList.some(substring => request.url.includes(substring))) {
      this.loaderService.show();
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      },
      ),
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        // Hide loader in case of unexpected termination
        if (!loaderSkipUrlList.some(substring => request.url.includes(substring))) {
          this.loaderService.hide(); // Show loader for POST and PUT requests         
        }
      })
    );
  }
}
