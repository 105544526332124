import { IGraphChartsData } from "../models/dashboard.model";

export function getTopData(data: IGraphChartsData, limit: number) {
  // Sort data by count in descending order
  const sortedData = data.sort((a, b) => b.count - a.count);
  
  // Return top N items or all data if limit is not provided
  return limit ? sortedData.slice(0, limit) : sortedData;
}

export function getListData(data: IGraphChartsData): {names: string[], counts: number[] } {
  // Extract names and counts
  return {
      names: data.map(item => item.name),
      counts: data.map(item => item.count)
  };
}