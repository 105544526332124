import { HttpParams } from "@angular/common/http";

export function generateQueryParams(object: any): string{
  if(!object) return ''
  let params = new HttpParams();
  for(let key in object){
    if(object.hasOwnProperty(key)){
      params = params.set(key, object[key])
    }
  }
  return  '?' + params.toString()
}