import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
import { APP_CONFIG } from '../config/appConfig';
import { ISessionState, ISessionStates, IUser, IUsers } from '../models/user.model';
import { generateQueryParams } from '../utils/generateQueryParms';
import { ToastService } from './toast.service';
import { IUserActivities } from '../models/user.activity.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user = new BehaviorSubject<IUser>({} as IUser)
  public $user = this.user.asObservable();

  getUserById(userId: any) {
    const path = APP_CONFIG.baseUrl + 'users/' + userId;
    return this.http.get<IUser>(path).pipe(tap(user => {
      this.user.next(user)
    }));
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  getUsers(): Observable<IUsers> {
    const path = APP_CONFIG.baseUrl + 'users';
    return this.http.get<IUsers>(path);
  }

  getAppUsers(appId: string): Observable<IUsers> {
    const path = `${APP_CONFIG.baseUrl}users/app/${appId}`;
    return this.http.get<IUsers>(path);
  }

  updateUser(userForm: IUser) {
    const path = APP_CONFIG.baseUrl + 'users/' + userForm.id;
    return this.http.post(path, userForm).pipe(
      tap(response => {
        this.toastService.success('User successfully updated');
      }),
      catchError(error => {
        this.toastService.error('User updated failed');
        throw error;
      })
    );;
  }

  createUser(userForm: IUser) {
    const path = APP_CONFIG.baseUrl + 'users';
    return this.http.post(path, userForm).pipe(
      tap(response => {
        this.toastService.success('User Create updated');
      }),
      catchError(error => {
        this.toastService.error('User Create failed');
        throw error;
      })
    );;
  }
  resetAdminPassword(userId: string, newPassword: string) {
    const path = APP_CONFIG.baseUrl + `admin/${userId}/reset` + generateQueryParams({ newPassword });
    return this.http.post(path, {}).pipe(
      tap(response => {
        this.toastService.success('User password successfully updated');
      }),
      catchError(error => {
        this.toastService.error('User password updated failed');
        throw error;
      })
    );
  }
  unlockUserAccount(userId: number, message: string): Observable<IUser> {
    const path = `${APP_CONFIG.baseUrl}users/${userId}/unlock`;
    return this.http.get<IUser>(path).pipe(
      tap(() => this.toastService.success(message)),
      catchError((error) => {
        this.toastService.error('Failed to reset unsuccessful attempts');
        throw error;
      })
    );
  }

  getAllUserTypes(): Observable<{ id: number; typeName: string }[]> {
    const path = APP_CONFIG.baseUrl + 'users/types';
    return this.http.get<{ id: number; typeName: string }[]>(path);
  }

  deleteUser(userId: number): Observable<{ message: string }> {
    const path = `${APP_CONFIG.baseUrl}users/${userId}`;
    return this.http.delete<{ message: string }>(path).pipe(
      tap(() => {
        this.toastService.success('User deleted successfully');
      }),
      catchError((error) => {
        this.toastService.error('Failed to delete user');
        throw error;
      })
    );
  }

  getUserActivities(userId: any) {
    const path = `${APP_CONFIG.baseUrl}users/${userId}/activity`;
    return this.http.get<IUserActivities>(path);
  }

  getSessionTracking(userId: any, sessionId: any) {
    const path = `${APP_CONFIG.baseUrl}users/${userId}/sessions/${sessionId}/trial`;
    return this.http.get<ISessionStates>(path);
  }
}
