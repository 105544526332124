<nav aria-label="Pagination" class="usa-pagination customize-paginator" *ngIf="totalPages > 1">
  <ul class="usa-pagination__list">
    <!-- Previous Button -->
    <li class="usa-pagination__item usa-pagination__arrow">
      <a
        href="javascript:void(0);"
        class="usa-pagination__link usa-pagination__previous-page"
        aria-label="Previous page"
        (click)="goToPreviousPage()"
        [class.usa-pagination__link--disabled]="pageNumber === 1"
      >
        <span class="usa-pagination__link-text">Previous</span>
      </a>
    </li>

    <ng-container *ngIf="pageNumber >= 3">
      <li class="usa-pagination__item usa-pagination__page-no">
        <a
          href="javascript:void(0);"
          class="usa-pagination__button"
          aria-label="Page 1"
          (click)="goToFirstPage()"
          >1</a
        >
      </li>
      <li
        class="usa-pagination__item usa-pagination__overflow"
        aria-label="ellipsis indicating non-visible pages"
      >
        <span>…</span>
      </li>
    </ng-container>
    

    <!-- Page Numbers with Ellipsis -->
    <ng-container *ngFor="let page of pages; let last = last">
      <ng-container *ngIf="last && page === totalPages">
        <li
        class="usa-pagination__item usa-pagination__overflow"
        aria-label="ellipsis indicating non-visible pages"
      >
        <span>…</span>
      </li>
      </ng-container>
      
      <li  class="usa-pagination__item usa-pagination__page-no">
        <a
          href="javascript:void(0);"
          class="usa-pagination__button"
          [class.usa-current]="page === pageNumber"
          [attr.aria-current]="page === pageNumber ? 'page' : null"
          (click)="goToPage(page)"
        >
          {{ page }}
        </a>
      </li>
    </ng-container>
    
   

    <!-- Next Button -->
    <li class="usa-pagination__item usa-pagination__arrow">
      <a
        href="javascript:void(0);"
        class="usa-pagination__link usa-pagination__next-page"
        aria-label="Next page"
        (click)="goToNextPage()"
        [class.usa-pagination__link--disabled]="pageNumber === totalPages"
      >
        <span class="usa-pagination__link-text">Next</span>
      </a>
    </li>
    
  </ul>
</nav>