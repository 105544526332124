import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  // Success Toast
  success(message: string, title?: string) {
    this.toastr.success(message, title, toastOptions);
  }

  // Error Toast
  error(message: string, title?: string) {
    this.toastr.error(message, title, toastOptions);
  }


  // Warning Toast
  warning(message: string, title?: string) {
    this.toastr.warning(message, title, toastOptions);
  }
}

const toastOptions: Partial<IndividualConfig<any>> | undefined = { 
  progressAnimation: 'decreasing',
  progressBar: true
}