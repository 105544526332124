import { CommonModule, NgFor } from '@angular/common';
import { Component, DestroyRef, OnInit, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { PaginatorComponent } from '@src/app/components/paginator/paginator.component';
import { IUserActivities } from '@src/app/core/models/user.activity.model';
import { IUser } from '@src/app/core/models/user.model';
import { UserService } from '@src/app/core/services/user.service';
import { searchDataByKeys } from '@src/app/core/utils/searchDataByKeys';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css'],
  standalone: true,
  imports: [FormsModule, NgFor, CommonModule, PaginatorComponent],
})
export class UserActivityComponent implements OnInit {
  filterCriteria = signal('');    // Signal for filter criteria from input
  currentPage = signal<number>(1);
  pageSize = 10;
  appId!: string;
  userId: any;
  userActivity = signal<IUserActivities>([]);
  user!: IUser;

  constructor(
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private destroyRef: DestroyRef
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params: Params) => {
      this.userId = params['id'];
    });
    this.userService.$user.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((response) => {
      this.user = response;
    });
    this.getUserActivities(this.userId);
  }
  getUserActivities(userId: any) {
    this.userService.getUserActivities(userId).subscribe((userActivities) => {
      this.userActivity.set(userActivities);
    })
  }

  paginateUserActivity = computed(() => {
    const startIndex = (this.currentPage() - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.filteredUserActivity().slice(startIndex, endIndex);
  });
  filteredUserActivity = computed(() => {
    const search = this.filterCriteria().toLowerCase();
    return searchDataByKeys(this.userActivity(), search, ['action', 'message', 'appName', 'createTimeStamp']);
  });
  onPageChanged(page: number): void {
    this.currentPage.update(() => page);
  }
}
