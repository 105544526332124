<section class="grid-container padding-x-sm-2 padding-y-2">
  <h1 class="usa-heading">User Activities</h1>
  <div class="usa-identifier margin-bottom-2 width-full align-start">
    <section class="usa-identifier__section usa-identifier__section--masthead padding-bottom-0"
      aria-label="Agency identifier,">
      <div class="usa-identifier__container">
        <section class="usa-identifier__identity" aria-label="Agency description,">
          <p class="usa-identifier__identity-domain">{{user.firstName}} {{user.lastName}}</p>
          <p class="usa-identifier__identity-disclaimer">
            {{user.email}}
          </p>
        </section>
      </div>
    </section>
  </div>
  <!-- Grid for User Table -->
  <div class="table-grid">
    <table class="usa-table usa-table--borderless usa-table--striped full-width-table">
      <thead>
        <tr>
          <th>ACTION</th>
          <th>APP</th>
          <th>MESSAGE</th>
          <th>TIMESTAMP</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of paginateUserActivity()">
          <td>{{ user.action }}</td>
          <td>{{ user.appName }}</td>
          <td>{{ user.message }}</td>
          <td class="max-width-column">{{ user.createTimeStamp | date:'yyyy-MM-dd HH:mm:ss' }}</td>
        </tr>
      </tbody>
    </table>
    <app-paginator [pageNumber]="currentPage()" [totalData]="filteredUserActivity().length || 0" [pageSize]="pageSize"
      (pageChanged)="onPageChanged($event)"></app-paginator>
  </div>
</section>