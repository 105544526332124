import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@src/app/core/services/auth.service';
import { NavigateService } from '@src/app/core/services/navigate.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css'],
  standalone: true,
  imports: [CommonModule ,ReactiveFormsModule],
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;
  exchangeToken!: string;
  isLoading: boolean = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private authService: AuthService, 
     private navigateService: NavigateService) {
    this.otpForm = this.fb.group({
      otpCode: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.exchangeToken = this.route.snapshot.queryParamMap.get('exchangeToken') || '';
  }

  verifyOTP() {
    if (this.otpForm.valid) {
      const otp = this.otpForm.value.otpCode;
      this.isLoading = true;
      this.authService.otpLogin(otp, this.exchangeToken).pipe(
        finalize(() => {
         this.isLoading = false;
        })
      ).subscribe({
        next: (response) =>{
         this.navigateService.toApp();
        },
        error:(error) =>{
         this.navigateService.toLogin();
        }
      })
    }
  }
}
