import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `
    <div [class.loader-content]="loading">
      <div class="blocker" *ngIf="loading">
        <span class="loader"></span>
      </div>
      <ng-content class="content"></ng-content>
    </div>
  `,
  styleUrls: ['./app-spinner.component.css'],
  standalone: true,
  imports:[CommonModule]
})
export class AppSpinnerComponent {
  @Input() loading!: boolean;  
}
