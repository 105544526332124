<div class="reset-password-container">
  <img src="assets/logo.png" alt="Company Logo" class="logo" /> <!-- Use your company logo -->
  <h2>Reset Password</h2>
 
  <div class="confirm-card" role="alert" *ngIf="isShowConfirm ">
    Confirm password does not match
  </div>

  <label class="display-flex" for="newPassword">New Password</label>
  <div class="usa-input-group" style="margin-bottom: 20px;">
    <input 
      id="newPassword" 
      [type]="isPasswordVisible ? 'text' : 'password'" 
      class="usa-input" 
      name="newPassword"
      [(ngModel)]="newPassword" 
      placeholder="Enter new password"
    >
    <button type="button" class="margin-right-1 usa-button usa-button--unstyled" (click)="isPasswordVisible = !isPasswordVisible">
      <img *ngIf="!isPasswordVisible" src="assets/svg/visibility_off_eye.svg" alt="Reset Password Icon" />
      <img *ngIf="isPasswordVisible" src="assets/svg/visibility_eye.svg" alt="Reset Password Icon" />
    </button>
  </div>

  <label class="display-flex" for="confirmPassword">Confirm Password</label>
  <div class="usa-input-group">
    <input 
      name="confirmPassword"
      id="confirmPassword" 
      [type]="isConfirmPasswordVisible ? 'text' : 'password'" 
      class="usa-input" 
      [(ngModel)]="confirmPassword" 
      placeholder="Confirm new password"
    >
    <button type="button" class="margin-right-1 usa-button usa-button--unstyled" (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible">
      <img *ngIf="!isConfirmPasswordVisible" src="assets/svg/visibility_off_eye.svg" alt="Reset Password Icon" />
      <img *ngIf="isConfirmPasswordVisible" src="assets/svg/visibility_eye.svg" alt="Reset Password Icon" />
    </button>
  </div>

  <button style="min-width: 90px;" type="button" class="usa-button confirm-button" [disabled]="isSubmitDisabled" (click)="onSubmit()">
    @if (isLoading){
      ...
    } @else {
      Confirm
    }
  </button>

</div>
