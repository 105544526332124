import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUsersCount } from '@src/app/core/models/app-users-count.model';
import { Subscription, switchMap, takeWhile, tap, timer } from 'rxjs';
import { IApp } from '../../core/models/app.model';
import { AppService } from '../../core/services/app.service';
import { NavigateService } from '../../core/services/navigate.service';

@Component({
  templateUrl: './app-form.component.html',
  styleUrls: ['./app-form.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule],
  providers: [DatePipe]
})
export class AppFormComponent implements OnInit {
  appId!: string | null;
  appForm: IApp = {
    id: 0,
    name: '',
    description: '',
    active: false,
  };

  showDeleteConfirmation: boolean = false;
  private subscription: Subscription = new Subscription();
  formattedDate: string = '';
  appUserCount: AppUsersCount = {
    totalNumberOfUsers: 0,
    processedUser: 0,
    reconcileFlag: false,
    lastReconciled: ''
  };
  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private navigateService: NavigateService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.appId = params['appId'] || null;

      if (this.appId) {
        this.getApp();
        this.processedUsers();
      }
    });
  }

  getApp(): void {
    this.appService.getAppById(Number(this.appId)).subscribe((app) => {
      this.appForm = { ...app };
    });
  }

  onSubmit(): void {
    this.appForm.name = this.appForm.name?.trim();
    if (this.appId) {
      this.appService.updateApp(this.appForm).subscribe(() => {
        console.log('App updated successfully');
      });
    } else {
      this.appService.createApp(this.appForm).subscribe((response) => {
        const appEditPath = this.navigateService.getAppEditPath(response?.id?.toString())
        this.router.navigate([appEditPath]);
      });
    }
  }

  closeForm(): void {
    this.navigateService.toAppsManagement();
  }

  reconsileUser() {
    this.appService.reconsileUser(true, Number(this.appId)).subscribe(() => {
      this.subscription = timer(4000, 1000) // Start after 2s, then poll every 1s
        .pipe(
          switchMap(() => this.appService.getProcessedUser(Number(this.appId))),
          tap((response: any) => {
            this.appUserCount = response;
            this.formattedDate = this.datePipe.transform(this.appUserCount.lastReconciled, 'yyyy-MM-dd HH:mm:ss') || '';
          }),
          takeWhile((response: any) => response.reconcileFlag, true) // Emit final value before stopping
        )
        .subscribe({
          error: (error) => console.error('API Error:', error),
          complete: () => console.log('Reconciliation process completed'),
        });
    });
  }
  processedUsers() {
    this.appService.getProcessedUser(Number(this.appId)).subscribe
      ((response: any) => {
        // Update UI values
        this.appUserCount = response;
        this.formattedDate = this.datePipe.transform(this.appUserCount.lastReconciled, 'yyyy-MM-dd HH:mm:ss') || '';

      })
  }
  openDeleteConfirmation(): void {
    this.showDeleteConfirmation = true;
  }
  cancelDelete(): void {
    this.showDeleteConfirmation = false;
  }
  confirmDelete(): void {
    if (this.appId) {
      this.appService.deleteApp(Number(this.appId)).subscribe({
        next: () => {
          console.log('App deleted successfully');
          this.navigateService.toAppsManagement();
        },
        error: (err) => {
          console.error('Failed to delete the app:', err);
          alert('An error occurred while deleting the app. Please try again.');
        },
      });
    }
    this.showDeleteConfirmation = false;
  }

}