<div class="app-sidenav">
  <nav class="navigation" aria-label="Side navigation">
    <ul class="usa-sidenav">
      <li class="usa-sidenav__item" [class.usa-current]="isActiveEditPath" [routerLink]="getEditPath">
        <a href="javascript:void(0);">Details</a>
      </li>
      <li class="usa-sidenav__item" [class.usa-current]="isActiveConfigPath" [routerLink]="getConfigPath">
        <a href="javascript:void(0);">Configuration</a>
      </li>
      <li class="usa-sidenav__item" [class.usa-current]="isActiveUsersPath" [routerLink]="getUsersPath">
        <a href="javascript:void(0);">Users</a>
      </li>
      <li class="usa-sidenav__item" [class.usa-current]="isAppActivity" [routerLink]="getAppActivityPath">
        <a href="javascript:void(0);">Activity</a>
      </li>
    </ul>
  </nav>

  <section class="sidenav-container">
    <router-outlet></router-outlet>
  </section>
</div>