import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class PaginatorComponent implements OnInit {

  @Input() totalData: number = 0;  // Total number of items
  @Input() pageSize: number = 25;  // Number of items per page
  @Input() pageNumber: number = 1; // Current page number

  @Output() pageChanged = new EventEmitter<number>();

  totalPages: number = 0;
  pages: number[] = [];

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.totalData / this.pageSize);
    this.generatePageNumbers();
  }

  ngOnChanges(): void {
    this.totalPages = Math.ceil(this.totalData / this.pageSize);
    this.generatePageNumbers();
  }

  generatePageNumbers(): void {
    this.pages = [];

    if (this.totalPages <= 4) {
      // Show all pages if there are 4 or fewer pages
      this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      if (this.pageNumber <= 2) {
        // Show the first 4 pages if the user is near the start
        this.pages = [1, 2, 3, 4];
      } else if (this.pageNumber >= this.totalPages - 1) {
        // Show the last 4 pages if the user is near the end
        this.pages = [
          this.totalPages - 3,
          this.totalPages - 2,
          this.totalPages - 1,
          this.totalPages
        ];
      } else {
        // Show the current page and 1 page on each side
        this.pages = [
          this.pageNumber - 1,
          this.pageNumber,
          this.pageNumber + 1,
          this.totalPages
        ];
      }
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.pageNumber = page;
      this.pageChanged.emit(this.pageNumber);
    }
  }

  goToPreviousPage(): void {
    if (this.pageNumber > 1) {
      this.goToPage(this.pageNumber - 1);
    }
  }

  goToNextPage(): void {
    if (this.pageNumber < this.totalPages) {
      this.goToPage(this.pageNumber + 1);
    }
  }

  goToFirstPage(): void {
    this.goToPage(1);
  }

  goToLastPage(): void {
    this.goToPage(this.totalPages);
  }

}
