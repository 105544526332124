import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  standalone: true,
  imports: [FormsModule,CommonModule,ReactiveFormsModule]
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  constructor(private fb: FormBuilder,public router: Router, private authService: AuthService) {
    this.forgotPasswordForm = this.fb.group({
      username: ['', [Validators.required]]
      });
   }

   ngOnInit(): void {}

  onSubmit() {
    const username = this.forgotPasswordForm.value.username;
    this.authService.forgetPassword(username).subscribe({
      next:() =>{
        this.router.navigate(['/login']);
      }
    })
  }
}
