import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap } from 'rxjs';
import { APP_CONFIG } from '../config/appConfig';
import { IApp, IApps } from '../models/app.model';
import { IGroupUpdatePayload, IGroups } from '../models/configuration.model';
import { ToastService } from './toast.service';
import { IUserActivities } from '../models/user.activity.model';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: HttpClient, private toastService: ToastService) { }

  getApps(): Observable<IApps> {
    const path = APP_CONFIG.baseUrl + 'apps';
    return this.http.get<IApps>(path);
  }

  getAppById(appId: number): Observable<IApp> {
    const path = APP_CONFIG.baseUrl + `apps/${appId}`;
    return this.http.get<IApp>(path);
  }

  updateApp(appForm: IApp): Observable<any> {
    const path = APP_CONFIG.baseUrl + `apps/${appForm.id}`;
    return this.http.put(path, appForm).pipe(
      tap(() => {
        this.toastService.success('App successfully updated');
      }),
      catchError((error) => {
        this.toastService.error('App update failed');
        throw error;
      })
    );
  }

  createApp(app: IApp): Observable<IApp> {
    const path = `${APP_CONFIG.baseUrl}apps`;
    return this.http.post<IApp>(path, app).pipe(
      tap(() => {
        this.toastService.success('App successfully Created');
      }),
      catchError((error) => {
        this.toastService.error('App created failed');
        throw error;
      })
    );
  }
  reconsileUser(reconcileFlag: boolean, appId: number) {
    const path = `${APP_CONFIG.iamUrl}reconcile/users?reconcileFlag=${reconcileFlag}&appId=${appId}`;
    return this.http.get(path, { responseType: 'text' });
  }
  deleteApp(appId: number): Observable<any> {
    const path = APP_CONFIG.baseUrl + `apps/${appId}`;
    return this.http.delete(path, { responseType: 'text' }).pipe(
      tap(() => {
        this.toastService.success('App successfully deleted');
      }),
      catchError((error) => {
        this.toastService.error('App deletion failed');
        throw error;
      })
    );
  }
  getConfigurationByAppId(appId: string): Observable<IGroups> {
    const path = APP_CONFIG.baseUrl + `apps/${appId}/config`;
    return this.http.get<IGroups>(path).pipe(
      map((response) => {
        return response
      }),
      catchError((error) => {
        this.toastService.error('Configuration load failed');
        throw error;
      })
    );
  }

  updateConfigurationByAppId(appId: string, payload: IGroupUpdatePayload): Observable<any> {
    const path = APP_CONFIG.baseUrl + `apps/${appId}/config`;
    return this.http.post<any>(path, payload).pipe(
      map((response) => {
        this.toastService.success('Configuration Updated successfully');
        return response
      }),
      catchError((error) => {
        this.toastService.error('Configuration Update failed');
        throw error;
      })
    );
  }

  getProcessedUser(appId: number): any {
    const path = APP_CONFIG.iamUrl + `process/users?appId=${appId}`;
    return this.http.get(path);
  }
  getAppActivities(appId: any, filters: any) {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        params = params.set(key, filters[key]);
      }
    });
    const path = `${APP_CONFIG.baseUrl}apps/${appId}/activity`;
    return this.http.get<IUserActivities>(path, { params });
  }
}
