import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { NavigateService } from '@services/navigate.service';
import { LoggedUser } from '@src/app/core/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  styleUrls: ['./header.component.css'],
  imports: [CommonModule, RouterModule]
})
export class HeaderComponent implements OnInit {
  isDropdownVisible = signal(false);
  userInfo = signal<LoggedUser>({} as LoggedUser)

  constructor(private authService: AuthService , public navigateService: NavigateService) { }

  ngOnInit() {
    this.getUserInfo();
  }

  getUserInfo(){
   const userInfo = this.authService.getUserInfo();
   this.userInfo.update(() => userInfo);
  }

  toggleDropdown() {
    console.log('toggle')
    this.isDropdownVisible.update(isShown => !isShown);
  }

  logout() {
    this.authService.singOut().subscribe({next: (response) =>{
      this.navigateService.toLogin();
    }, error:(err) => {
      console.log(err);
      this.navigateService.toLogin();
    }})
    
  }

}
