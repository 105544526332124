import { CommonModule, NgFor } from '@angular/common';
import { Component, OnInit, computed, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { PaginatorComponent } from '@src/app/components/paginator/paginator.component';
import { IUserActivities } from '@src/app/core/models/user.activity.model';
import { AppService } from '@src/app/core/services/app.service';
import { searchDataByKeys } from '@src/app/core/utils/searchDataByKeys';

@Component({
  selector: 'app-activity',
  templateUrl: './app-activity.component.html',
  styleUrls: ['./app-activity.component.css'],
  standalone: true,
  imports: [FormsModule, NgFor, CommonModule, PaginatorComponent],
})
export class AppActivityComponent implements OnInit {
  filterCriteria = signal('');    // Signal for filter criteria from input
  currentPage = signal<number>(1);
  pageSize = 10;
  appId!: string;
  appActivity = signal<IUserActivities>([]);
  filters = {
    limit: '',
    status: '',
    startDate: '',
    endDate: ''
  };

  limits = [50, 100, 150];
  searchResults: string[] = [];
  constructor(
    private activeRoute: ActivatedRoute,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params: Params) => {
      this.appId = params['appId'];
    });
    this.getAppActivities(this.appId);
  }
  getAppActivities(appId: any) {
    this.appService.getAppActivities(appId, this.filters).subscribe((userActivities) => {
      this.appActivity.set(userActivities);
    })
  }

  paginateAppActivity = computed(() => {
    const startIndex = (this.currentPage() - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.filteredAppActivity().slice(startIndex, endIndex);
  });
  filteredAppActivity = computed(() => {
    const search = this.filterCriteria().toLowerCase();
    return searchDataByKeys(this.appActivity(), search, ['action', 'message', 'userName', 'createTimeStamp']);
  });
  onPageChanged(page: number): void {
    this.currentPage.update(() => page);
  }
  onSearch() {
    this.getAppActivities(this.appId);
  }

}
