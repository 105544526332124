import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';


import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AuthTokenInterceptor } from '@core/interceptors/authToken.interceptor';
import { LoaderInterceptor } from '@core/interceptors/loaderInterceptor';
import { RequestHeaderInterceptor } from '@core/interceptors/requestHeader.interceptor';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideToastr } from 'ngx-toastr';
import { appRoutes } from './app.routes';
import { HttpErrorsInterceptor } from './core/interceptors/http-errors.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes), provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true,
    },
    provideAnimations(), 
    provideToastr({
      timeOut: 5000, // 5 seconds
      positionClass: 'toast-top-right', // Toast will appear in the top-right corner
      preventDuplicates: true, // show all toast
    }), 
    provideCharts(withDefaultRegisterables())
  ],
};
