  <div class="header-custom-one">
    <div class="usa-navbar">
      <a title="Home" aria-label="Home" class="logo-items">
        <img src="assets/logo.png" alt="Logo" class="logo-img">
        <span class="logo-span text-white">AMS</span>
      </a>
    </div>
    <div class="profile-section">
      <button class="usa-button usa-button--unstyled" id="profile-button" aria-controls="profile-dropdown" aria-expanded="false" aria-haspopup="true" (click)="toggleDropdown()">
        <img src="assets/profile.png" alt="Profile Icon" class="profile-icon">
        <span style="margin-left: 10px;" class="text-white">{{userInfo().fullName}}</span>
      </button>
    
      <ul id="profile-dropdown" class="usa-nav__submenu" *ngIf="isDropdownVisible()">
        <!-- <li><a><i class="fas fa-user"></i> Profile</a></li>
        <li><a><i class="fas fa-cog"></i> Settings</a></li> -->
        <li (click)="logout()"><a><i class="fas fa-sign-out-alt"></i> Logout</a></li>
      </ul>
    </div>
  </div>
  
  <header class="usa-header usa-header--basic">
    <div class="usa-nav-container">
    
      <nav class="usa-nav">
        <ul class="usa-nav__primary">
          <li class="usa-nav__primary-item">
            <a class="usa-nav__link" 
            [routerLinkActive]="'usa-current'"
            [routerLink]="navigateService.appDashboardPath">Dashboard</a>
          </li>
         
          <li class="usa-nav__primary-item">
            <a 
              class="usa-nav__link" 
              [routerLink]="navigateService.appManagementPath" 
              [routerLinkActive]="'usa-current'">
              Apps
            </a>
          </li>
          <li class="usa-nav__primary-item">
            <a class="usa-nav__link " 
            [routerLink]="navigateService.userManagementPath"
            [routerLinkActive]="'usa-current'" 
            >Users</a>
          </li>
          <li class="usa-nav__primary-item">
            <a 
              class="usa-nav__link" 
              [routerLink]="navigateService.systemConfigurationPath" 
              [routerLinkActive]="'usa-current'">
              Configuration
            </a>
          </li>
        </ul>

      </nav>
    </div>
  </header>