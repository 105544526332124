<div class="app-sidenav">
  <nav class="navigation" aria-label="Side navigation">
    <ul class="usa-sidenav">
      <li class="usa-sidenav__item" [class.usa-current]="isActiveEditPath" [routerLink]="getEditPath">
        <a href="javascript:void(0);">Edit user</a>
      </li>
      <li class="usa-sidenav__item" [class.usa-current]="isSessionPath" [routerLink]="getSessionPath">
        <a href="javascript:void(0);">Sessions</a>
      </li>
      <li class="usa-sidenav__item" [class.usa-current]="isActivity" [routerLink]="getActivityPath">
        <a href="javascript:void(0);">Activity</a>
      </li>
    </ul>
  </nav>
  
  <section class="sidenav-container">
    <router-outlet></router-outlet>
  </section>
</div>
