
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastService } from '@src/app/core/services/toast.service';
import { REGEX_PATTERN } from '../../core/config/regex-pattern';
import { IApps } from '../../core/models/app.model';
import { IUser } from '../../core/models/user.model';
import { AppService } from '../../core/services/app.service';
import { NavigateService } from '../../core/services/navigate.service';
import { UserService } from '../../core/services/user.service';
import { markAllAsTouchedAndDirty } from '../../core/utils/markAllAsTouchedAndDirty';
import { ResetPasswordModalComponent } from '../../reset-password-modal/reset-password-modal.component';


@Component({
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule, ResetPasswordModalComponent],
})
export class UserFormComponent implements OnInit {
  isEditMode = false;
  userId!: string;
  formGroup: FormGroup<IUserFormModel> = createInitialForm();
  apps: IApps = [];
  userInfo!: IUser

  availableApps: { id: number; name: string; selected: boolean }[] = [];
  userTypes: { id: number; typeName: string }[] = [];
  showDeleteConfirmation: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private appService: AppService,
    private navigateService: NavigateService,
    private toastService: ToastService,
    private destroyRef: DestroyRef

  ) {
    this.fetchAvailableApps();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.userId = params['id'];
    });
  
    this.fetchUserTypes();

    this.userService.$user.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((response) =>{
      if(!this.userId) return;
      this.userInfo = { ...response };
      this.formGroup.patchValue({
        ...response,
        userType: response.userType?.id || null // Set the userType as its ID
    });;
      this.onUpdateApps();
    })
   
  }

  fetchUserTypes() {
    this.userService.getAllUserTypes().subscribe(
      (response) => {
        this.userTypes = response; // Populate user types
      },
      (error) => {
        console.error('Error fetching user types:', error);
      }
    );
  }

  onUpdateApps(){
    this.availableApps = this.apps.map((app) => ({
      id: app.id,
      name: app.name,
      selected: this.userInfo?.apps?.some((userApp) => userApp.id === app.id)
    }));
  }

  fetchAvailableApps() {
    this.appService.getApps().subscribe(
      (response) => {
        this.apps = response;
        this.onUpdateApps();
      },
      (error) => {
        console.error('Error fetching apps:', error);
      }
    );
  }
  

  onSubmit() {
    markAllAsTouchedAndDirty(this.formGroup);
    if(!this.formGroup.valid){
      return
    }
    const selectedApps = this.availableApps
      .filter((app) => app.selected) 
      .map((app) => ({ id: app.id, name: app.name }));
    const formsValue = this.formGroup.value;
    const selectedUserType = formsValue.userType;
    
    const userType = this.userTypes.find(
      (type) => type.id === Number (selectedUserType)
    );
    if(userType?.typeName?.toLowerCase() !== 'admin' && selectedApps?.length < 1){
      this.toastService.error('At least one app selection is mandatory');
      return;
    }
    const payload =  {...this.userInfo, apps: selectedApps, ...formsValue, userType: userType || null,} as IUser
    console.log("Selected userType ID:", selectedUserType);
    console.log('Payload being sent:', payload);
    this.userId ? this.onUpdateUser(payload) : this.onCreateUser(payload)
  
  }

  onCreateUser(payload: IUser){
    this.userService.createUser(payload).subscribe(
      (response) => {
        this.navigateService.toUserManagement()
      },
      (error) => {
        console.error('Error updating user:', error);
      }
    );
  }

  onUpdateUser(payload: IUser){
    this.userService.updateUser(payload).subscribe({
      next:(response) => {
        console.log('User updated successfully:', response);
      },
      error: (error) => {
        console.error('Error updating user:', error);
      }
    }
    );
  }

  updateUserState(updatedUser: IUser) {
    
    this.userInfo.unsuccessfulAttempts = updatedUser.unsuccessfulAttempts;
    this.userInfo.locked = updatedUser.locked;
  
    
    this.formGroup.patchValue({
      locked: updatedUser.locked ?? false,
    });
    
    this.formGroup.patchValue({
      unsuccessfulAttempts: updatedUser.unsuccessfulAttempts ?? 0, 
    });
    
  }

  onResetUnsuccessfulAttempts() {
    if (!this.userId) return;
  
    this.userService.unlockUserAccount(+this.userId, 'Unsuccessful attempts reset successfully').subscribe({
      next: (updatedUser) => {
        this.updateUserState(updatedUser);
      },
      error: (err) => {
        console.error('Error resetting unsuccessful attempts:', err);
      },
    });
  }
  
  onUnlockUserAccount() {
    if (!this.userId) return;
  
    this.userService.unlockUserAccount(+this.userId, 'Unlocked successfully').subscribe({
      next: (updatedUser) => {
        this.updateUserState(updatedUser);
      },
      error: (err) => {
        console.error('Error unlocking user account:', err);
      },
    });
  }

  // Delete user methods
  openDeleteConfirmation(): void {
    console.log("Delete Confirmation Opened");
    this.showDeleteConfirmation = true;
  }

  cancelDelete(): void {
    this.showDeleteConfirmation = false;
  }

  confirmDelete(): void {
    if (this.userId) {
      this.userService.deleteUser(+this.userId).subscribe({
        next: (response) => {
          console.log(response.message);
          this.navigateService.toUserManagement(); // Navigate back to user management page
        },
        error: (err) => {
          console.error('Failed to delete the user:', err);
          alert('An error occurred while deleting the user. Please try again.');
        },
      });
    }
    this.showDeleteConfirmation = false;
  }

  closeForm() {
    this.navigateService.toApp();
  }
}

export function createInitialForm():  FormGroup<IUserFormModel>{
 return new FormGroup<IUserFormModel>({
  userName: new FormControl<string>({ value: '', disabled: false }, [Validators.required]),
  firstName: new FormControl<string>({ value: '',  disabled: false }, [Validators.required]),
  lastName: new FormControl<string>({ value: '',  disabled: false }, [Validators.required]),
  middleName: new FormControl<string>({ value: '',  disabled: false }, []),
  email: new FormControl<string>({ value: '',  disabled: false }, [Validators.required, Validators.pattern(REGEX_PATTERN.EMAIL)]),
  active: new FormControl<boolean>({ value: true,  disabled: false }, []),
  locked: new FormControl<boolean>({ value: false,  disabled: false }, []),
  override: new FormControl<boolean>({ value: false,  disabled: false }, []),
  unsuccessfulAttempts: new FormControl<number>(0), 
  userType: new FormControl<number | null>(null, [Validators.required]),
 })
}


export interface IUserFormModel{
  userName: FormControl<string | null>;
  firstName: FormControl<string | null>;
  lastName:  FormControl<string | null>;
  middleName:  FormControl<string | null>;
  email:  FormControl<string | null>;
  active:  FormControl<boolean | null>;
  locked:  FormControl<boolean | null>;
  override:  FormControl<boolean | null>;
  unsuccessfulAttempts: FormControl<number | null>;
  userType: FormControl<number | null>;
}