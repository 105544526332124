<section class="grid-container padding-x-sm-2 padding-y-2">
  <h1 class="usa-heading">Users</h1>

  <!-- Grid for Filter and Create Button -->
  <div class="filter-grid">
    <input type="text" class="usa-input" placeholder="Search User..." (input)="onFilter($event)" />
    <button class="usa-button" routerLinkActive="router-link-active" (click)="createUser()">Create</button>
  </div>

  <!-- Grid for User Table -->
  <div class="table-grid">
    <table class="usa-table usa-table--borderless usa-table--striped full-width-table">
      <thead>
        <tr>
          <th>User name</th>
          <th>Name</th>
          <th class="max-width-column">Email</th>
          <th *ngIf="!appId">Apps</th>
          <th>Status</th>
          <th *ngIf="!appId">App Name</th>
          <th>Recent Login</th>
          <th>Recent Logout</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of paginateUsers()">
          <td><a href="javascript:void(0)" (click)="editUser(user)"> {{ user.userName }}</a></td>
          <td>{{ user.firstName }}, {{ user.lastName }}</td>
          <!-- <td></td> -->
          <td class="max-width-column">{{ user.email }}</td>
          <td *ngIf="!appId">
            <ng-container *ngIf="user.apps && user.apps.length > 0; else noApps">
              <span *ngFor="let app of user.apps; let i = index">
                {{ app.name }}<span *ngIf="i < user.apps.length - 1">, </span>
              </span>
            </ng-container>
            <ng-template #noApps>—</ng-template>
          </td>
          <td>{{ user.active ? 'Active' : 'Inactive' }}</td>
          <td *ngIf="!appId"> {{user.appName}}</td>
          <td>{{user.recentLoginDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
          <td>{{user.recentLogoutDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>

        </tr>
      </tbody>
    </table>
    <app-paginator [pageNumber]="currentPage()" [totalData]="filteredUsers().length || 0" [pageSize]="pageSize"
      (pageChanged)="onPageChanged($event)"></app-paginator>
  </div>
</section>