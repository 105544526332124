import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { REGEX_PATTERN } from '@src/app/core/config/regex-pattern';
import { IGroupUpdatePayload, IGroups } from '@src/app/core/models/configuration.model';
import { AppService } from '@src/app/core/services/app.service';
import { NavigateService } from '@src/app/core/services/navigate.service';
import { SystemService } from '@src/app/core/services/system.service';
import { markAllAsTouchedAndDirty } from '@src/app/core/utils/markAllAsTouchedAndDirty';

@Component({
  templateUrl: './config-forms.component.html',
  styleUrls: ['./config-forms.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule]
})
export class ConfigFormsComponent implements OnInit {
  dynamicForm: FormGroup;
  groupedFields: IGroups = [];
  appId!: string;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, 
    private appService: AppService, private systemService: SystemService, private navigateService: NavigateService) {
    this.dynamicForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.appId = this.route.snapshot.params['appId'];
    if(this.appId) this.getAppConfigurations();
    else this.getSystemConfigurations();
  }

  getAppConfigurations(){
    this.appService.getConfigurationByAppId(this.appId).pipe().subscribe(response =>{
      this.groupedFields = response;
      this.generateDynamicFromGroup();
    })
  }

  getSystemConfigurations(){
    this.systemService.getConfiguration().pipe().subscribe(response =>{
      this.groupedFields = response;
      this.generateDynamicFromGroup();
    })
  }

  onSubmit(): void {
    markAllAsTouchedAndDirty(this.dynamicForm);
    if (this.dynamicForm.invalid) {
      return;
    }
    const formValues = this.dynamicForm.value; // Raw form values
    const payload = this.getPayloadFormatData(formValues);
    if(this.appId) this.appService.updateConfigurationByAppId(this.appId, payload).subscribe();
    else this.systemService.updateConfiguration(payload).subscribe();
  }

  generateDynamicFromGroup(){
    this.groupedFields.forEach((group) => {
      const groupControls: any = {};
      group.configs.forEach((field) => {
        const control = new FormControl(
          field.type === 'CHECKBOX' ? (field.value ?? false) : field.value,
          field.type === 'EMAIL'
            ? [Validators.pattern(REGEX_PATTERN.EMAIL)]
            : []
        );
        groupControls[field.keyName] = control;
      });
      this.dynamicForm.addControl(group.groupName, this.fb.group(groupControls));
    });
  }

  getPayloadFormatData(formValues: any) {
    const payload: IGroupUpdatePayload = [];
    const groupedFields = structuredClone(this.groupedFields)
    Object.keys(formValues).forEach((groupName) => {
      const group = groupedFields.find((g) => g.groupName === groupName);
      if (group) {
        Object.keys(formValues[groupName]).forEach((controlName) => {
          const field = group.configs.find((f) => f.keyName === controlName);
          const keyValue =   formValues[groupName][controlName];
          const keyId = field?.keyId || controlName;
          payload.push({
            keyId: keyId,
            value: keyValue?.toString() ?? null,
          });
        });
      }
    });

    return payload;
  }

  closeForm(): void {
    this.navigateService.toAppsManagement();
  }

}