<div class="modal">
    <div class="modal-content">
        <div class="modal__main">
            <h2 class="modal__heading">Session Tracking</h2>
            <div class="table-grid">
                <table class="usa-table usa-table--borderless usa-table--striped full-width-table">
                    <thead>
                        <tr>
                            <th>SESSION ID</th>
                            <th>STATUS</th>
                            <th>TIMESTAMP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let state of sessionStates">
                            <td>{{state.sessionId}}</td>
                            <td>{{state.status.name}}</td>
                            <td>{{ state?.createdTimeStamp ? (state?.createdTimeStamp | date:'yyyy-MM-dd HH:mm:ss') : '' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="usa-button usa-button--outline" (click)="onCloseModal()">Close</button>
          </div>
    </div>
</div>