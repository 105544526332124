<section class="grid-container padding-x-sm-2 padding-y-2 usa-section">
  <div class="container" *ngIf="dynamicForm">
    <h2 *ngIf="appId; else systemConfig">App Configuration</h2>
    <ng-template #systemConfig>
      <h2>System Configuration</h2>
    </ng-template>
    <form [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
      <div *ngFor="let group of groupedFields">
        <h3>{{ group.label }}</h3>
        <ng-container *ngIf="group.configs as groups">
          <div *ngFor="let field of (groups || [])" class="form-group" [formGroupName]="group.groupName">
            <label [for]="field.label">{{ field.label }}</label>

            <!-- Text, NUMERIC, Email Input Fields -->
            <ng-container [ngSwitch]="field.type">
              <input *ngSwitchCase="'TEXT'" [id]="field.keyName" type="text" class="usa-input"
                [formControlName]="field.keyName" [placeholder]="field.label" />

              <textarea *ngSwitchCase="'TEXTAREA'" [id]="field.keyName" class="usa-input"
                [formControlName]="field.keyName" [placeholder]="field.label" rows="4" cols="50"></textarea>
              <input *ngSwitchCase="'NUMERIC'" [id]="field.keyName" type="number" class="usa-input"
                [formControlName]="field.keyName" [placeholder]="field.label" />
              <input *ngSwitchCase="'NUMBER'" [id]="field.keyName" type="number" class="usa-input" step="1"
                [formControlName]="field.keyName" [placeholder]="field.label" />
              <input *ngSwitchCase="'EMAIL'" [id]="field.keyName" type="email" class="usa-input"
                [formControlName]="field.keyName" [placeholder]="field.label" />

              <!-- Password Field -->
              <input *ngSwitchCase="'PASSWORD'" [id]="field.keyName" type="password" class="usa-input"
                [formControlName]="field.keyName" [placeholder]="field.label" />


              <!-- Checkbox Field -->
              <input *ngSwitchCase="'CHECKBOX'" [id]="field.keyName" type="checkbox" class="margin-left-1"
                [formControlName]="field.keyName" />

              <!-- not found Field -->
              <p *ngSwitchDefault>This filed type not found, need to add this config : {{field?.type}}</p>

            </ng-container>

            <!-- Validation Error Messages -->
            <div
              *ngIf="dynamicForm.get([group.groupName, field.keyName])?.invalid && dynamicForm.get([group.groupName, field.keyName])?.touched">
              <small class="error-message"
                *ngIf="dynamicForm.get([group.groupName, field.keyName])?.errors?.['required']">
                {{ field.label }} is required.
              </small>
              <small class="error-message"
                *ngIf="field.type === 'EMAIL' && dynamicForm.get([group.groupName, field.keyName])?.invalid">
                Please enter a valid email address.
              </small>
            </div>
          </div>
        </ng-container>

      </div>

      <div class="margin-top-4">
        <button type="submit" class="usa-button">Update</button>
        <button type="button" (click)="closeForm()" class="usa-button usa-button--outline">Cancel</button>
      </div>
    </form>
  </div>
</section>