import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartDataset } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { take } from 'rxjs';
import { IGraphChartsData } from '../core/models/dashboard.model';
import { DashboardService } from '../core/services/dashboard.service';
import { getListData, getTopData } from '../core/utils/graphConvertData';

@Component({
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, BaseChartDirective]
})
export class AdminDashboardComponent implements OnInit {
  userActivity = USER_ACTIVITY;
  appsActivity = Apps_ACTIVITY;
  appSessionActivity = Apps_Session_ACTIVITY;

  totalApps: number = 0;
  totalUsers: number = 0;
  activeUsers: number = 0;
 

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  // Uses Chart Data
  usersChartData: ChartDataset<'line'>[] = [
    {
      data: [],
      label: 'User Activity'
    }
  ];
  usersChartLabels: string[] = [];
  selectedDays = 5;

   // Anonymous Chart Data
   anonymousChartData: ChartDataset<'line'>[] = [
    {
      data: [],
      label: 'Anonymous Activity'
    }
  ];
  anonymousChartLabels: string[] = [];
  anonymousSelectedDays = 5;


  //App Chart Data
  appChartData: ChartDataset<'bar'>[]  = [
    {
      data: [],
      label: 'App Users'
    }
  ];
  appChartLabels: string[] = [];
  appsChartData: IGraphChartsData = [];
  selectedAppNumber: number = 5;

   // Session Chart Data
   sessionChartData: ChartDataset<'bar'>[]  = [
    {
      data: [],
      label: 'App Sessions'
    }
  ];
  sessionChartLabels: string[] = [];
  selectedSessionNumber: number = 5;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
     this.dashboardService.getTotalApps().pipe(take(1)).subscribe(response =>{
       this.totalApps = response;
     });

     this.dashboardService.getTotalUsers().pipe(take(1)).subscribe(response =>{
      this.totalUsers = response;
    });

    this.dashboardService.getLiveUsers().pipe(take(1)).subscribe(response =>{
      this.activeUsers = response;
    });

    this.getAppsActiveUser();
    this.geUserActivity();
    this.getAppSessionActivity();
    this.getAnonymousUserActivity()
  }

  getAppsActiveUser(){
    this.dashboardService.getAppsActiveUser().pipe(take(1)).subscribe(response =>{
      this.appsChartData = response;
      this.filterTopApps();
    })
  }

  geUserActivity(){
    this.dashboardService.getUserActivity(this.selectedDays).pipe(take(1)).subscribe(response =>{
      const { names, counts} = getListData(response);
      this.usersChartData[0].data = counts;
      this.usersChartLabels = names;
    })
  }

  getAnonymousUserActivity(){
    this.dashboardService.getAnonymousUserActivity(this.anonymousSelectedDays).pipe(take(1)).subscribe(response =>{
      const { names, counts} = getListData(response);
      this.anonymousChartData[0].data = counts;
      this.anonymousChartLabels = names;
    })
  }


  getAppSessionActivity(){
    this.dashboardService.getAppSessionActivity(this.anonymousSelectedDays).pipe(take(1)).subscribe(response =>{
      const { names, counts} = getListData(response);
      this.sessionChartData[0].data = counts;
      this.sessionChartLabels = names;
    })
  }

  filterTopApps(){
    const topApps = getTopData(this.appsChartData, this.selectedAppNumber);
    const { names, counts} = getListData(topApps)
    this.appChartData[0].data = counts;
    this.appChartLabels = names;
  }



 
}


const USER_ACTIVITY =  [
  {
    value: 5,
    label: "Last 5 Days"
  },
  {
    value: 10,
    label: "Last 10 Days"
  },
  {
    value: 20,
    label: "Last 20 Days"
  },
  {
    value: 30,
    label: "Last 30 Days"
  }
]

const Apps_ACTIVITY =  [
  {
    value: 5,
    label: "Top 5 Apps"
  },
  {
    value: 10,
    label: "Top 10 Apps"
  },
  {
    value: 15,
    label: "Top 15 Apps"
  },
  {
    value: 10000,
    label: "All"
  }
]


const Apps_Session_ACTIVITY =  [
  {
    value: 5,
    label: "Last 5 Days"
  },
  {
    value: 10,
    label: "Last 10 Days"
  },
  {
    value: 15,
    label: "Last 15 Days"
  },
  {
    value: 20,
    label: "Last 20 Days"
  }
]