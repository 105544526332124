import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/config/appConfig';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoggedUser } from '../models/user.model';
import { LocalStoreService } from './local-store.service';
import { ToastService } from './toast.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

constructor(private http: HttpClient, private localStore: LocalStoreService,
  private toastService: ToastService) { }

  signIn(username: string, password: string): Observable<string>{
    const path = APP_CONFIG.baseUrl + 'admin/login';
    const authHeader = `Basic ${btoa(`${username}:${password}`)}`;
    // Set headers with the authorization
    const headers = new HttpHeaders({
      'Authorization': authHeader,
    });
    // Make the API call with Basic Authentication
    return this.http.post(path,{}, { headers, responseType: 'text' })
    .pipe();
  }
  otpLogin(otp: string, exchangeToken: string): Observable<any>{
    const path = APP_CONFIG.baseUrl + 'admin/verify';
    return this.http.post(path,{otp, exchangeToken}, {responseType: 'text' }).pipe( 
      tap(token => {
        this.localStore.setToken(token);
        this.localStore.setSession(token);
        const tokenInfo = JSON.parse(atob(exchangeToken.split('.')[1]));
        const user = tokenInfo?.user as LoggedUser;
        this.localStore.setUserInfo(user)
      }),
      catchError(error =>{
        console.log(error);
        this.toastService.error('OTP login failed')
        throw error;
      })
    );
  }

  singOut(): Observable<any>{
    const path = APP_CONFIG.baseUrl + 'admin/logout';
    const sessionId = this.localStore.getSession()
    const headers = new HttpHeaders({
      'SessionId': sessionId,
    });
    return this.http.post(path,{}, {headers, responseType: 'text' }).pipe( 
      tap(response => {
        this.localStore.logOut();
      }),
      catchError(error =>{
        this.localStore.logOut();
        throw error;
      })
    );
  }

  forgetPassword(username: string): Observable<any>{
    const path = APP_CONFIG.baseUrl + 'admin/forgot';
    return this.http.post(path,{username}, {responseType: 'text' }).pipe( 
      tap(response => {
        this.toastService.success('Reset Password Link is sent to the registered email.')
        console.log(response);
      }),
      catchError(error =>{
        console.log(error);
        this.toastService.error('Error forgot password api')
        throw error;
      })
    );
  }

  resetPassword({newpassword, extoken, username}:{newpassword: string; extoken: string; username: string}): Observable<any>{
    const path = APP_CONFIG.baseUrl + 'admin/forgot/reset';
    const atopPass = this.localStore.strEncript(newpassword);
    return this.http.post(path,{newpassword: atopPass, extoken, username}, {responseType: 'text' }).pipe( 
      tap(response => {
        this.toastService.success('Your Password Reset is Successful')
        console.log(response);
      }),
      catchError(error =>{
        console.log(error);
        this.toastService.error('Error reset password api')
        throw error;
      })
    );
  }


  isLogin(){
   return this.localStore.isLogin()
  }

  getUserInfo(): LoggedUser{
    const userinfo = this.localStore.getUserInfo()
    return {...userinfo, fullName: `${userinfo?.firstName || ''} ${userinfo?.lastName || ''}` }
  }

}
