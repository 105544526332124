import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function markAllAsTouchedAndDirty(control: AbstractControl): void {
	control.markAsTouched();
	control.markAsDirty();
	control.updateValueAndValidity();

	if (control instanceof FormGroup) {
		for (const key in control.controls) {
			markAllAsTouchedAndDirty(control.controls[key]);
		}
	} else if (control instanceof FormArray) {
		control.controls.forEach((element) => {
			markAllAsTouchedAndDirty(element);
		});
	}
}
