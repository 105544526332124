<section class="grid-container padding-x-sm-2 padding-y-2">
  <h1 class="usa-heading">Sessions</h1>

  <div class="usa-identifier margin-bottom-2 width-full align-start">
    <section
      class="usa-identifier__section usa-identifier__section--masthead padding-bottom-0"
      aria-label="Agency identifier,"
    >
      <div class="usa-identifier__container">
        <section
          class="usa-identifier__identity"
          aria-label="Agency description,"
        >
          <p class="usa-identifier__identity-domain">{{user.firstName}} {{user.lastName}}</p>
          <p class="usa-identifier__identity-disclaimer">
            {{user.email}}
          </p>
        </section>
      </div>
    </section>
  </div>

  <!-- Grid for Filter and Create Button -->
  <!-- <div class="filter-grid">
    <input type="text" class="usa-input" placeholder="Search Session..."  />

  </div> -->

  <!-- Grid for User Table -->
  <div class="table-grid">
    <table class="usa-table usa-table--borderless usa-table--striped full-width-table">
      <thead>
        <tr>
          <th>SESSION ID</th>
          <th>APP </th>
          <th>STATUS</th>
          <th>STATUS DESCRIPTION</th>
          <th>START TIME</th>
          <th>END TIME</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let session of paginateSession()">
          <td><a href="javascript:void(0)" (click)="openSessionTrackingModal(session.id)"> {{ session?.id }}</a></td>
          <td>{{ session?.app?.name || '' }}</td>
          <td>{{ session?.status?.name}}</td>
          <td>{{ session?.status?.description}}</td>
          <td>{{ session?.startDateTime ? (session.startDateTime | date:'yyyy-MM-dd HH:mm:ss') : '' }}</td>
          <td>{{ session?.endDateTime ? (session.endDateTime | date:'yyyy-MM-dd HH:mm:ss') : '' }}</td>
        </tr>
        <app-user-session-tracking-modal *ngIf="isOpenSessionTrackingModal" [sessionStates]="sessionStates" (closeModal)="closeSessionTrackingModal()"></app-user-session-tracking-modal>
      </tbody>
    </table>
    <app-paginator [pageNumber]="currentPage()" [totalData]="filteredSessions().length || 0" 
    [pageSize]="pageSize" (pageChanged)="onPageChanged($event)"></app-paginator>
  </div>
</section>