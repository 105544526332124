import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { NavigateService } from '@src/app/core/services/navigate.service';
import { UserService } from '@src/app/core/services/user.service';

@Component({
  selector: 'app-user-side-nav',
  templateUrl: './user-side-nav.component.html',
  styleUrls: ['./user-side-nav.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class UserSideNavComponent implements OnInit {
  public currentPath!: string;
  public paramsId!: string;
  
  constructor(private activeRoute: ActivatedRoute, private router: Router, private destroyRef: DestroyRef,
      private userService: UserService,  private navigateService: NavigateService ){
  }

  ngOnInit(): void {
    this.paramsId = this.activeRoute.firstChild?.snapshot.params['id'];
    this.currentPath = this.router.url;

    this.router.events.subscribe(event =>{
     if(event instanceof NavigationEnd){
      this.currentPath = this.router.url;
     }
    });
    this.getUserById();
  }


  getUserById() {
    if(!this.paramsId) return;
    this.userService.getUserById(this.paramsId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  get getEditPath(){
    return this.navigateService.getUserEditPath(this.paramsId);
  }

  get getSessionPath(){
    return this.navigateService.getUserSessionPath(this.paramsId);
  }
  get getActivityPath(){
    return this.navigateService.getUserActivityPath(this.paramsId);
  }
  get isActiveEditPath(){
    return this.currentPath === this.getEditPath;
  }
  get isSessionPath(){
    return this.currentPath === this.getSessionPath;
  }
  
  get isActivity(){
    return this.currentPath === this.getActivityPath;
  }
}
