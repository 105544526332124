<div class="forgot-password-container">
    <img src="assets/logo.png" alt="Company Logo" class="logo" /> <!-- Use your company logo -->
    <h2>Forgot Password</h2>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input id="userName" type="username" formControlName="username" placeholder="Enter your username" />
        <div *ngIf="forgotPasswordForm.get('username')?.invalid && forgotPasswordForm.get('username')?.touched">
          <small class="error">Username is required</small>
        </div>
      </div>
  
      <button type="submit" [disabled]="forgotPasswordForm.invalid">Submit</button>
    </form>
  
    <div class="back-to-login">
      <a (click)="router.navigate(['/login'])">Back to Login</a>
    </div>
  </div>
  