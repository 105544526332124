import { CommonModule, NgFor } from '@angular/common';
import { Component, DestroyRef, OnInit, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { PaginatorComponent } from '@src/app/components/paginator/paginator.component';
import { ISession, ISessionStates, IUser } from '@src/app/core/models/user.model';
import { UserService } from '@src/app/core/services/user.service';
import { searchDataByKeys } from '@src/app/core/utils/searchDataByKeys';
import { UserSessionTrackingModalComponent } from "./user-session-tracking-modal/user-session-tracking-modal.component";

@Component({
  selector: 'app-user-sessions',
  templateUrl: './user-sessions.component.html',
  styleUrls: ['./user-sessions.component.css'],
  standalone: true,
  imports: [FormsModule, NgFor, CommonModule, PaginatorComponent, UserSessionTrackingModalComponent],
})
export class UserSessionsComponent implements OnInit {
  sessionData = signal<ISession[]>([]);
  user!: IUser;
  filterCriteria = signal('');  
  currentPage = signal<number>(1);
  pageSize = 10;
  isOpenSessionTrackingModal = false;
  selectedSessionId!: number;
  sessionStates!: ISessionStates;

  filteredSessions = computed(() => {
    const search = this.filterCriteria().toLowerCase();
    return searchDataByKeys(this.sessionData(), search, [])
  });

  paginateSession= computed(() => {
    const startIndex = (this.currentPage() - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.filteredSessions().slice(startIndex, endIndex);
  });
  
  constructor( private userService: UserService, private destroyRef: DestroyRef) { }

  ngOnInit() {

    this.userService.$user.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((response) =>{
     const sessions = response?.sessions || []
     this.sessionData.update(()=> sessions);
     this.user = response;
    });
  }

  onPageChanged(page: number){
    this.currentPage.update((pre) =>page )
  }

  openSessionTrackingModal(sessionId: number) {
    this.userService.getSessionTracking(this.user.id, sessionId)
    .subscribe(response => {
      this.sessionStates = response;
      this.isOpenSessionTrackingModal = true;
    });
    // this.isOpenSessionTrackingModal = true;
    // this.selectedSessionId = sessionId;
  }

  closeSessionTrackingModal() {
    this.isOpenSessionTrackingModal = false;
  }

}
