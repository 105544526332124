import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastService } from "./toast.service";
import { catchError, map, Observable } from "rxjs";
import { IGroups, IGroupUpdatePayload } from "../models/configuration.model";
import { APP_CONFIG } from "../config/appConfig";

@Injectable({
  providedIn: 'root',
})
export class SystemService {

    constructor(private http: HttpClient, private toastService: ToastService) {}

    getConfiguration(): Observable<IGroups> {
        const path = APP_CONFIG.baseUrl + `config`;
        return this.http.get<IGroups>(path).pipe(
          map((response) => {
            return response
          }),
          catchError((error) => {
            this.toastService.error('Configuration load failed');
            throw error;
          })
        );
      }
    
      updateConfiguration(payload: IGroupUpdatePayload): Observable<any> {
        const path = APP_CONFIG.baseUrl + `config`;
        return this.http.post<any>(path, payload).pipe(
          map((response) => {
            this.toastService.success('Configuration Updated successfully');
            return response
          }),
          catchError((error) => {
            this.toastService.error('Configuration Update failed');
            throw error;
          })
        );
      }

}