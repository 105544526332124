<section class="grid-container padding-x-sm-2 padding-y-2 usa-section">
  
  <div class="modal-content">
    <h2>{{ userId ? 'Edit User' : 'Create User' }}</h2>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="username">Username*</label>
        <input type="text" id="userName" class="usa-input" formControlName="userName" name="userName" [readonly]="!!userId" />
        <div *ngIf="formGroup.controls.userName.touched && formGroup.controls.userName.invalid"
          class="error-message">
           Username is required
        </div>
      </div>
      <div class="form-group">
        <label for="username">First Name*</label>
        <input type="text" id="firstName" class="usa-input" formControlName="firstName" name="firstName" />
        <div *ngIf="formGroup.controls.firstName.touched && formGroup.controls.firstName.invalid"
          class="error-message">
          First Name is required
        </div>
      </div>
      <div class="form-group">
        <label for="username">Last Name*</label>
        <input type="text" id="lastName" class="usa-input" formControlName="lastName" name="lastName" />
        <div *ngIf="formGroup.controls.lastName.touched && formGroup.controls.lastName.invalid"
          class="error-message">
          Last Name is required
        </div>
      </div>
      <div class="form-group">
        <label for="username">Middle Name</label>
        <input type="text" id="lastName" class="usa-input" formControlName="middleName" name="middleName" />
      </div>
      <div class="form-group">
        <label for="email">Email*</label>
        <input type="email" id="email" class="usa-input" formControlName="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"/>
        <div *ngIf="formGroup.controls.email.touched && formGroup.controls.email.invalid"
          class="error-message">
          Please enter valid email address
        </div>

        <div class="form-group margin-y-2">
          <label for="userType">User Type*</label>
          <select id="userType" class="usa-select" formControlName="userType">
            <option *ngFor="let type of userTypes" [value]="type.id">
              {{ type.typeName }}
            </option>
          </select>
          <div *ngIf="formGroup.controls.userType.touched && formGroup.controls.userType.invalid" class="error-message">
            User Type is required
          </div>
        </div>
        

      </div>
      <div class="form-group" *ngIf="userId">
        <label for="unsuccessfulAttempts">Unsuccessful Attempts</label>
        <div class="usa-input-group d-flex no-gap align-items-center">
          <input
          type="number"
          id="unsuccessfulAttempts"
          class="usa-input"
          formControlName="unsuccessfulAttempts"
          readonly
        />
        <button
          *ngIf="(formGroup.get('unsuccessfulAttempts')?.value || 0) > 0"
          type="button"
          class="usa-button no-gap-button"
          (click)="onResetUnsuccessfulAttempts()"
        >
          RESET
        </button>
      </div> 
      </div>    
      
      <ng-container *ngIf="userId">
        <div for="user status" class="margin-y-2" style="font-weight: bold;">User Status</div>
        <div class="form-group" >
          <input type="checkbox" [checked]="formGroup.value.active" formControlName="active" name="active" id="active" />
          <label class="margin-left-1" for="active"> Active</label>
        </div>
        
        <div class="form-group" >
          <div class="d-flex align-items-center">
            <input 
              type="checkbox" 
              [checked]="formGroup.get('locked')?.value" 
              id="locked" 
              class="non-editable-checkbox"
            />
            <label class="margin-left-1 margin-right-1"> Locked</label>
            <button
              *ngIf="formGroup.get('locked')?.value" 
              type="button" 
              class="usa-button usa-button-small margin-left-2"
              (click)="onUnlockUserAccount()"
            >
              Unlock
            </button>
          </div>
        </div>

        <div class="form-group" >
          <input type="checkbox" [checked]="formGroup.value.override" formControlName="override"name="override" id="override" />
          <label class="margin-left-1"> Disable Email Override</label>
        </div> 
  
      </ng-container>
      

      <div >
        <div for="apps" class="margin-y-2" style="font-weight: bold;">Apps</div>
        <div class="app-wrap-container">
          <ng-container *ngFor="let app of availableApps;">
            <div class="form-group child-item" >
              <input
                type="checkbox"
                id="app_{{ app.id }}"
                [(ngModel)]="app.selected"
                [ngModelOptions]="{ standalone: true }"
                class="margin-right-1"
                name="app_{{ app.id }}"/>
                <label for="app_{{ app.id }}">{{ app.name }}</label>
            </div>
          </ng-container> 
        </div>
        
      </div>


      <div class="margin-top-4">
        <button type="submit" class="usa-button" [disabled]="!formGroup.valid">{{ userId ? 'Update' : 'Create' }}</button>
        <app-reset-password-modal *ngIf="userId" [userId]="userId"></app-reset-password-modal>
        <button
        *ngIf="userId"
        type="button"
        class="usa-button usa-button--secondary"
        (click)="openDeleteConfirmation()"
      >
        Delete
      </button>
      <button type="button" class="usa-button usa-button--outline" (click)="closeForm()">Cancel</button>
    </div>
    </form>
  </div>
</section>
<div class="delete-modal" *ngIf="showDeleteConfirmation" >
  <div class="delete-modal-content">
    <h3>Are you sure you want to delete this user?</h3>
    <div class="delete-modal-actions">
      <button type="button" class="usa-button" (click)="confirmDelete()">Confirm</button>
      <button type="button" class="usa-button usa-button--outline" (click)="cancelDelete()">Cancel</button>
    </div>
  </div>
</div>