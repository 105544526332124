import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {

  constructor(private router: Router, private location: Location) { }

  toApp(): void {
    this.router.navigate([this.appDashboardPath]);
  }
  toLogin(): void {
    this.router.navigate(['/login']);
  }
  toForgetPassword(): void {
    this.router.navigate(['/forgot-password']);
  }
  toUserManagement(): void {
    this.router.navigate(['/app/admin/users']);
  }
  toAppsManagement(): void {
    this.router.navigate(['/app/admin/apps']);
  }
  toActiveSession() {
    this.router.navigate([this.activeSessionPath])
  }
  toUserEditForm(id: number) {
    this.router.navigate([`app/admin/users/edit/${id}`]);
  }

  toAppCreateForm(): void {
    this.router.navigate(['/app/admin/apps/create']);
  }

  toAppEditForm(appId: number): void {
    this.router.navigate([`/app/admin/apps/edit/${appId}`]);
  }
  toOtp(exchangeToken: string): void {
    this.router.navigate([`/otp`], {
      queryParams: {
        exchangeToken
      }
    });
  }

  toCreateUser(): void {
    this.router.navigate([`/app/admin/users/create`]);
  }

  toConfigurations(): void {
    this.router.navigate([`/app/configurations`]);
  }

  getAppEditPath(id: string) {
    return this.appManagementPath + '/edit/' + id;
  }
  getAppConfigPath(id: string) {
    return this.appManagementPath + '/configuration/' + id;
  }
  getAppUsersPath(id: string) {
    return this.appManagementPath + '/users/' + id;
  }
  getUserEditPath(id: string) {
    return this.userManagementPath + '/edit/' + id;
  }
  getUserSessionPath(id: string) {
    return this.userManagementPath + '/sessions/' + id;
  }
  getUserActivityPath(paramsId: string) {
    return this.userManagementPath + '/activity/' + paramsId;
  }
  get userManagementPath() {
    return '/app/admin/users'
  }
  get activeSessionPath() {
    return '/app/admin/sessions'
  }

  get appManagementPath() {
    return '/app/admin/apps';
  }

  get appDashboardPath() {
    return '/app/dashboard';
  }

  get systemConfigurationPath() {
    return '/app/configuration';
  }
  getAppActivityPath(paramsId: string) {
    return this.appManagementPath + '/activity/' + paramsId;
  }
}