import { CommonModule, NgFor } from '@angular/common';
import { Component, DestroyRef, OnInit, computed, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IUser, IUsers } from '@src/app/core/models/user.model';
import { UserService } from '@src/app/core/services/user.service';
import { searchDataByKeys } from '@src/app/core/utils/searchDataByKeys';
import { PaginatorComponent } from '@src/app/components/paginator/paginator.component';
import { NavigateService } from '@src/app/core/services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  standalone: true,
  imports: [FormsModule, NgFor, CommonModule, PaginatorComponent],
})

export class UserListComponent implements OnInit {

  userData = signal<IUsers>([]);
  filterCriteria = signal('');    // Signal for filter criteria from input
  isFormOpen = false; // To control the modal visibility
  isEditing = false; // To track if editing or creating a new user
  currentUserIndex: number | null = null; // Track index for editing
  currentPage = signal<number>(1);
  pageSize = 10;
  appId!: string;

  filteredUsers = computed(() => {
    const search = this.filterCriteria().toLowerCase();
    return searchDataByKeys(this.userData(), search, ['userName', 'firstName', 'lastName', 'middleName', 'email','apps'])
  });

  paginateUsers = computed(() => {
    const startIndex = (this.currentPage() - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.filteredUsers().slice(startIndex, endIndex);
  });

  constructor(
    private activeRoute: ActivatedRoute, 
    private userService: UserService, 
    private navigateService: NavigateService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.appId = this.activeRoute.snapshot.params['appId'];
    if(this.appId) this.getAppUsers(this.appId);
    else this.getUsers();
  }

  getUsers(): void {
    this.userService.getUsers().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (users) => {
        this.userData.set(users)
      }
    })
  }

  getAppUsers(appId: string): void {
    this.userService.getAppUsers(appId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (users) => {
        this.userData.set(users)
      }
    })
  }

  // Search functionality
  onFilter(event: Event) {
    const input = (event.target as HTMLInputElement).value;
    this.currentPage.update(pre => 1);
    this.filterCriteria.set(input);
  }

  // Open form for editing a user
  editUser(user: IUser) {
    this.navigateService.toUserEditForm(user.id);
  }

  createUser(){
    this.navigateService.toCreateUser();
  }



  // Delete user
  deleteUser(user: IUser) {
    const userId = user.id;
    this.userData.update((users) => users.filter(user => user.id !== userId));
  }

  onPageChanged(page: number){
    this.currentPage.update((pre) =>page )
  }
}