<button type="button" class="usa-button" (click)="isModalOpen = true">Change Password</button>

<div class="modal"  
  *ngIf="isModalOpen"
  id="example-modal-1"
  aria-hidden="true">
  <div class="modal-content">
    <div class="modal__main">
      
      <h2 class="modal__heading">Change Password</h2>

      <div class="confirm-card" role="alert" *ngIf="isShowConfirm ">
        Confirm password does not match
      </div>

      <label for="newPassword">New Password</label>
      <div class="usa-input-group" style="margin-bottom: 20px;">
        <input 
          id="newPassword" 
          [type]="isPasswordVisible ? 'text' : 'password'" 
          class="usa-input" 
          name="newPassword"
          [(ngModel)]="newPassword" 
          placeholder="Enter new password"
        >
        <button type="button" class="usa-button usa-button--unstyled" (click)="isPasswordVisible = !isPasswordVisible">
          <img *ngIf="!isPasswordVisible" src="assets/svg/visibility_off_eye.svg" alt="Reset Password Icon" />
          <img *ngIf="isPasswordVisible" src="assets/svg/visibility_eye.svg" alt="Reset Password Icon" />
        </button>
      </div>

      <label for="confirmPassword">Confirm Password</label>
      <div class="usa-input-group">
        <input 
          name="confirmPassword"
          id="confirmPassword" 
          [type]="isConfirmPasswordVisible ? 'text' : 'password'" 
          class="usa-input" 
          [(ngModel)]="confirmPassword" 
          placeholder="Confirm new password"
        >
        <button type="button" class="usa-button usa-button--unstyled" (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible">
          <img *ngIf="!isConfirmPasswordVisible" src="assets/svg/visibility_off_eye.svg" alt="Reset Password Icon" />
          <img *ngIf="isConfirmPasswordVisible" src="assets/svg/visibility_eye.svg" alt="Reset Password Icon" />
        </button>
      </div>

      <div class="modal-footer">
        <button style="min-width: 90px;" type="button" class="usa-button" [disabled]="isSubmitDisabled" (click)="onResetPassword()">
          @if (isLoading){
            ...
          } @else {
            Confirm
          }
        </button>
        <button type="button" class="usa-button usa-button--outline" (click)="onCloseModal()">Cancel</button>
      </div>
     
    </div>
  </div>
</div>
