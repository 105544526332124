import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { APP_CONFIG } from '../config/appConfig';
import { IAppsActiveUserResponse, IAppsAnonymousUser, IAppsSessionActivity, IAppsUsesResponse, IGraphChartsData } from '../models/dashboard.model';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private toastService: ToastService) { }

  getTotalUsers(): Observable<number> {
    const path = APP_CONFIG.baseUrl + 'admin/dashboard/users/count';
    return this.http.get<{count: number}>(path)
      .pipe(map(response => response.count));
  }
  getTotalApps(): Observable<number> {
    const path = APP_CONFIG.baseUrl + 'admin/dashboard/apps/count';
    return this.http.get<{count: number}>(path)
      .pipe(map(response => response.count));
  }

  getLiveUsers(): Observable<number> {
    const path = APP_CONFIG.baseUrl + 'admin/dashboard/users/usage/live/metrics';
    return this.http.get<{count: number}>(path)
      .pipe(map(response => response.count));
  }
  
  getAppsActiveUser(): Observable<IGraphChartsData> {
    const path = APP_CONFIG.baseUrl + 'admin/dashboard/apps/users/count';
    return this.http.get<IAppsActiveUserResponse>(path)
      .pipe(map(response => {
        return response.map(({appName, usersCount}) =>({name: appName,count: usersCount  }));
      }));
  }

  getUserActivity(days: number): Observable<IGraphChartsData> {
    const path = APP_CONFIG.baseUrl + `admin/dashboard/users/usage/metrics?days=${days}`;
    return this.http.get<IAppsUsesResponse>(path)
      .pipe(map(response => {
        return response.map(({date, sessionsCount}) =>({name: date,count: sessionsCount  }));
      }));
  }

  getAnonymousUserActivity(days: number): Observable<IGraphChartsData> {
    const path = APP_CONFIG.baseUrl + `admin/dashboard/users/usage/anonymous/metrics?days=${days}`;
    return this.http.get<IAppsAnonymousUser[]>(path)
      .pipe(map(response => {
        return response.map(({date, count}) =>({name: date,count  }));
      }));
  }

  getAppSessionActivity(days: number): Observable<IGraphChartsData> {
    const path = APP_CONFIG.baseUrl + `admin/dashboard/apps/usage/metrics?days=${days}`;
    return this.http.get<IAppsSessionActivity[]>(path)
      .pipe(map(response => {
        return response.map(({appName, sessionsCount}) =>({name: appName,count: sessionsCount  }));
      }));
  }

}
